@charset "utf-8";
@import "sass/variables.scss";
@import "~bulma/bulma.sass";
@import url('https://fonts.googleapis.com/css?family=Coming+Soon|Fredericka+the+Great|Ruthie:400');

// High level
html, body, #root {
	height:100%;
}
html, body {
	width:100%;
	overflow-x:hidden;
	-webkit-text-size-adjust:100%;
	-webkit-font-smoothing:antialiased;
}
html {
	font-size:18px;
	background-image: url($texture1);
}
section {
	margin:2rem 0;
	
	a.link, a.link:hover, a.link:focus {
		/*display:inline-block;
		position:relative;*/
		text-decoration:underline;
		color:$burgundy;
		//border-bottom:2px solid $blue;
		padding:.2em 0;
		/*z-index:1000;*/
	}
	a.link:hover {
		background:rgba($burgundy, .16);
	}

	/*a.link:before {
		content:"";
		z-index:-1;
		width:0%;
		height:100%;
		background:rgba($burgundy, .16);
		bottom:0;
		left:0;
		position:absolute;
		transition:width 250ms;
	}
	a.link:hover:before {
		width:100%;
		z-index:111;
	}*/
}
.has-background-transparent {
	background-color:transparent!important;
}
p {
	margin-bottom:1rem;
}

// Components
.navbar-link, .navbar-link:hover {
	background:none!important;
}
.navbar-divider {
	background-color:rgba($burgundy, .15);
}
.navbar-item.is-active {
	font-weight:bolder;
}
.title {
	font-size: 2rem;
	font-family:$title-font;
	font-weight:400;
	color:$primary;
}
.title-font {
	font-family:$title-font;
	color:$primary;
	font-size:2.5rem;
}
.subtitle {
	font-size:1.75rem;
	font-family:$header-font;
	color:$secondary;

	small {
		color:$green;
		font-size:1.3rem;
	}
}
.title2 {
	font-size:1.15rem;
	font-family:$title-font;
	color:$green;

	small {
		color:$blue;
	}
}
.ring-loader {
	display:block;
	text-align:center;
	font-size:.6rem;
	margin:1.5rem 0;
	
	svg {
		display:block;
		width:1em;
		overflow:visible;
		font-size:6em;
		margin-left:auto;
		margin-right:auto;
	}
	path {
		stroke:$primary;
		stroke-width:5;
		stroke-dasharray:1740;
		stroke-dashoffset:1740;
	}
	path.fa-primary {
		animation:dash-rose 3s infinite;
	}
	path.fa-secondary {
		animation:dash-white 3s infinite;
	}
}
@keyframes dash-rose {
	0%, 100% {
		fill:rgba($pink, .65);
		stroke-dashoffset:1740;
	}
	50% {
		fill:rgba($pink, 0);
		stroke-dashoffset:0;
	}
}
@keyframes dash-white {
	0%, 100% {
		fill:rgba($white, .65);
		stroke-dashoffset:1740;
	}
	50% {
		fill:rgba($white, 0);
		stroke-dashoffset:0;
	}
}
.ticker {
	font-family:$title-font;
	color:$primary;
	line-height:.8;
	
	span {
		display: block;
		font-size:2rem;
	}
}

.landing {
	background: url('./imgs/wedding_party/bg.jpg');
	background-repeat:no-repeat;
	background-position: center center;
	background-attachment: fixed;
	background-size: cover;
	text-align:center;
	height:100%;
	padding-top:3rem;

	.ticker {
		padding-top:1rem;
		font-size:1.25rem;

		span {
			font-size: 2rem;
		}
	}
	.title-font {
		font-size:1.5rem;
	}
	.cta {
		margin-top:4rem;
	}
}
header {
	/*height: 100%;
	min-height:500px;*/
	text-align:center;
	padding-top:2rem;
	/*background:url($bg1) bottom center no-repeat;
	background-size:contain*/
}
.modal-card-body {
	background-image: url($texture2);
}
.navbar {
	background:none;

	.navbar-menu {
		padding:0!important;
	}
	.navbar-start {
		margin-left:auto;
		margin-right:auto;
		background-color: $white;
		background-image: url($texture2);
		box-shadow: 0 10px 6px -8px #777,
			5px 0 5px -5px #777,
			0px 0 3px #777;
		padding:.5rem 1rem;
		font-size:1rem;
	}
	.navbar-item {
		padding:.75rem 1.5rem;
		margin:0 .25rem;

		&.is-active {
			font-weight:900;
			background: url('./imgs/squiggle.png');
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 100% 80%;
		}
		&.is-active:hover {
			background-color: transparent;
		}
		&:hover {
			background-color: rgba($white, .25);
		}
	}
}
.table tr {
	.action {
		opacity:0;
	}
	&:hover .action {
		opacity:1;
	}
}
.card {
	background-image: url($texture2);
	box-shadow: 0 10px 6px -8px #777,
		5px 0 5px -5px #777,
		0px 0 3px #777;
	margin-bottom:2rem;
	overflow:visible;
	
	&.fixed-sm {
		display:block;
		width:25rem;
		margin:3rem auto;
		
		.link {
			text-align:center;
			font-size:90%;
			margin-top:3rem;
		}
	}

	&:not(.card-img)::before, &:not(.card-img)::after {
		z-index: -1;
		position: absolute;
		content: "";
		bottom: 15px;
		left: 20px;
		width: 50%;
		top: 80%;
		max-width:300px;
		background: #777;
		box-shadow: 0 15px 10px #777;
		transform: rotate(-3deg);
	}
	&::after {
		transform: rotate(3deg);
		right: 10px;
		left: auto;
	}
	.card-sticker {
		position: absolute;
		top: $avatar-size * -.55;
		left: calc(50% - (#{$avatar-size} * .5));
		width: $avatar-size;
		img {
			border: .25rem solid  #fff;
			box-shadow: 0 10px 6px -8px #777,
				5px 0 5px -5px #777,
				0px 0 3px #777;
		}
	}
	.card-sticker + .card-content {
		margin-top: $avatar-size * .45;
		padding-top: calc(1.5rem + (#{$avatar-size} * .35));
	}
}
.card.card-img {
	position:relative;
	padding:1rem;
	transform:rotate(12deg);
	margin:0 -2rem;
	z-index:-1;

	img {
		min-height:300px;
		width:auto;
		object-fit:cover;
	}
	.overlay {
		position:absolute;
		top:1.5rem;
		left:1.5rem;
		font-weight:bolder;
		color:$blue;
	}
}
.input, .input:hover, .input:focus, .textarea, .textarea:hover, .textarea:focus {
	background-color:rgba($background, .35);
	border:none;
	outline:none;
	box-shadow:none;
	border-radius:0;
	font-size:1rem;
}
.button, .button:hover, .button[disabled]   {
	background-color:$blue;
	color:$white;
	border-color:transparent;
}
.error {
	color:$red;
	padding:.5rem 1.5rem;
	font-size:.8rem;
	border-top:1px solid $red;
	border-bottom:1px solid $red;
	margin-bottom:2rem;
	text-align:center;
}
.lead {
	font-size:1.25rem;
}
.note {
	position:relative;
	font-size:.8rem;
	padding-left:1rem;
	margin-top:.5rem;

	&::before {
		content:"*";
		position:absolute;
		top:0;
		left:-.15rem;
		font-size:2rem;
		color:$primary;
		font-weight:900;
	}
}
.is-256x256 {
	width:256px;
	height:256px;
}
.card .media img.is-rounded {
	box-shadow: 0 10px 6px -8px #777,
		5px 0 5px -5px #777,
		0px 0 3px #777;
}

.register-form {
	text-align:center;
	
	.input {
		display:block;
		text-align:center;
		height:3rem;
		max-width:6rem;
		padding:.5rem 1rem;
		margin-left:auto;
		margin-right:auto;
	}
}

@media (min-width: 768px) {
	.title {
		font-size:2.5rem;
	}
	.landing {
		padding-top:4rem;
		
		.title {
			font-size:5rem;
		}
		.title-font {
			font-size:2.5rem;
		}
		.ticker {
			padding-top:4rem;
			font-size:2rem;

			span {
				font-size:4rem;
			}
		}
	}
}
@media (max-width: 1023px) {
	.media {
		flex-direction:column;

		.media-left, .media-right {
			margin-left:auto;
			margin-right:auto;
		}
		.media-left {
			order: 1;
		}
		.media-right {
			order: 2;
		}
		.media-content {
			order: 3;
		}
	}
}

.gallery {
    -moz-column-count: 3;
    -moz-column-gap: 12px;
    -webkit-column-count: 3;
    -webkit-column-gap: 12px;
    column-count: 3;
    column-gap: 12px;
    width: 100%;
}
.gallery img {
	display: inline-block;
    width:100%;
}

@media screen and (max-width: 500px) {
    .gallery {
        display: block;
    }
    .gallery img {
        width: 100%;
    }
}